import {PlotProps} from "components/PlotSelector/WithPlotSelector";
import {EventType} from "generated/graphql";
import {useTranslate} from "hooks/useTranslate";
import {useAddOrUpdateEvent} from "hooks/useAddOrUpdateEvent";
import {CountCard} from "components/CountCard/CountCard";

export const CollectPlot = ({ plot }: PlotProps) => {
  const addOrUpdate = useAddOrUpdateEvent(EventType.Selfing);
  const { t } = useTranslate("pollen.flowers");

  const selfingCrossing = plot.crossingsMother.filter(c => !c.father)[0];

  return <>
    <CountCard title={t('title')} required={plot.counts.toCollect} input={{ plotId: plot.id }} />

    { plot.counts.toSelf !== null && <>
        <CountCard title={t('selfing')}
           required={plot.counts.toSelf}
           allowMore={true}
           input={{ plotId: plot.id }}
           addOrUpdate={addOrUpdate}
           collectedLabel="selfing"
        />
      { selfingCrossing && <div style={{ marginTop: "-10px", marginBottom: "20px", color: "yellow", textAlign: "center" }}>{t('harvested')}: {selfingCrossing.result}, {t('desired')}: {selfingCrossing.count}</div> }
    </>}
  </>
}