import {ApolloClient, NormalizedCacheObject} from "@apollo/client";
import {EventType, EventUpdatedDocument, EventUpdatedSubscription, PlotCounts} from "generated/graphql";
import {connectionStatus, Status} from "components/StatusBar/StatusBar";

const getChange = ({ type, delta }: { type: EventType, delta: number }) => {
  switch (type){
    case EventType.Collect25: return [delta * 0.25, delta * 0.75];
    case EventType.Collect50: return [delta * 0.50, delta * 0.50];
    case EventType.Collect75: return [delta * 0.75, delta * 0.25];
    case EventType.Collect100: return [delta, 0];
    case EventType.Lost25: return [-delta * 0.25, delta * 0.25];
    case EventType.Fail: return [0, delta];
  }
  return [0,0];
}

interface Subscription {
  unsubscribe: () => void;
}

let inv: NodeJS.Timer | null = null;
let started = false;
let sub: Subscription | null = null;

export const startSubscription = (client: ApolloClient<NormalizedCacheObject>) => {
  if (!inv) {
    inv = setInterval(() => {
      if (connectionStatus() === Status.Disconnected) {
        subscribe(client);
      }
    }, 5000);
  }
  if (started) { return }
  started = true;
  subscribe(client);
}

const subscribe = (client: ApolloClient<NormalizedCacheObject>) => {
  const res = client.subscribe({
    query: EventUpdatedDocument
  });

  const process = (data: EventUpdatedSubscription) => {
    const cache = client.cache;
    const updated = data.eventUpdated;
    const crossingId = cache.identify({
      __typename: 'Crossing',
      id: updated.crossingId
    });
    if (updated.type === EventType.EditCount) {
      cache.modify({
        id: crossingId,
        fields: {
          count(existing) {
            return existing + updated.delta;
          }
        }
      });
    }
    else if (updated.type === EventType.Crossing) {
      cache.modify({
        id: crossingId,
        fields: {
          reserved(existing) {
            return existing - updated.delta;
          }
        }
      });
    }
    else if (updated.type === EventType.CountSeeds || updated.type === EventType.CorrectSeeds) {
      cache.modify({
        id: crossingId,
        fields: {
          seedsCounted(existing) {
            return existing + (updated.type === EventType.CorrectSeeds ? -1  : 1) * updated.delta;
          },
          seedsCountedToday(existing) {
            return existing + (updated.type === EventType.CorrectSeeds ? -1  : 1) * updated.delta;
          }
        }
      });
    }
    else if (updated.crossingId) {
      cache.modify({
        id: crossingId,
        fields: {
          result(existing) {
            return existing + getChange(updated)[0];
          },
          failed(existing) {
            return existing + getChange(updated)[1];
          }
        }
      });
    }
    if (updated.plotId) {
      cache.modify({
        id: cache.identify({
          __typename: 'Plot',
          id: updated.plotId
        }),
        fields: {
          counts(existing: PlotCounts) {
            switch (updated.type) {
              case EventType.Selfing:
                return {
                  ...existing,
                  toSelf: (existing.toSelf ?? 0) - updated.delta
                };
              case EventType.EpIn:
              case EventType.EpReturn:
                return {
                  ...existing,
                  toCollect: existing.toCollect - updated.delta,
                  available: existing.available + updated.delta
                };
              case EventType.EpOut:
                return {
                  ...existing,
                  toCollect: existing.toCollect + updated.delta,
                  available: existing.available - updated.delta
                };
              case EventType.Reserve:
                return {
                  ...existing,
                  available: existing.available - updated.delta,
                  reserved: existing.reserved + updated.delta
                };
              case EventType.EditCount:
                return {
                  ...existing,
                  toCollect: existing.toCollect + updated.delta
                };
              case EventType.Emasculate:
                return {
                  ...existing,
                  toEmasculate: existing.toEmasculate - updated.delta
                };
            }
            return existing;
          },
          available(existing: number) {
            switch (updated.type) {
              case EventType.Reserve:
              case EventType.EpOut:
                return existing - updated.delta;
              case EventType.EpIn:
              case EventType.EpReturn:
                return existing + updated.delta;
            }
          }
        }
      });

      if (updated.type === EventType.Emasculate) {
        cache.modify({
          id: `Site:${updated.siteId}`,
          fields: {
            emasculatedToday(existing) {
              return existing + updated.delta;
            }
          }
        });
      }
    }
  }

  if (sub) { sub.unsubscribe(); }
  sub = res.subscribe({
    next(result) {
      if (result.data) { process(result.data); }
    },
    error(error) {
      console.log(error);
    },
    complete() {
    }
  });
}