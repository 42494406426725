import { MenuItem } from "components/MenuItem/MenuItem";
import {useTranslate} from "hooks/useTranslate";

export const Seeds = () => {
  const { t } = useTranslate('seeds');

  return (
    <div>
      <h1>{t('title')}</h1>
      <div className="menu">
        <MenuItem name={t('in')} link="/seeds-in" img="/seedsin.png"/>
        <MenuItem name={t('out')} link="/seeds-out" img="/seedsout.png" />
        <MenuItem name={t('back')} link="/" img="/bee.png" />
      </div>
    </div>
  );
}