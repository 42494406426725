import {useTranslate} from "hooks/useTranslate";
import {MenuItem} from "components/MenuItem/MenuItem";
import {Suspense, useEffect, useRef, useState} from "react";
import {useQuery} from "@apollo/client";
import {EventType, GetSeedCrossingDocument} from "generated/graphql";
import {useAddEvent} from "hooks/useAddEvent";
import {Modal} from "components/Modal/Modal";
import "./CountSeeds.scss";
import {MenuButton} from "components/MenuItem/MenuButton";

interface Props {
  correct: boolean
}

export const CountSeeds = ({ correct }: Props) => {
  const [code, setCode] = useState("");
  const [value, setValue] = useState("");
  const [history, setHistory] = useState<string[]>([]);
  const [showWarning, setShowWarning] = useState(false);
  const [sending, setSending] = useState(false);
  const [hasEnteredCode, setHasEnteredCode] = useState(false);

  const { t } = useTranslate('seeds');
  const codeBoxRef = useRef<HTMLInputElement>(null);
  const countBoxRef = useRef<HTMLInputElement>(null);

  const { data, error } = useQuery(GetSeedCrossingDocument, {
    variables: { crossingCode: code },
    skip: code.length < 1,
    onCompleted(data) {
      if (data && data.crossing.seedsCountedToday > 0 && !correct && hasEnteredCode) {
        setShowWarning(true);
      }
      setHasEnteredCode(false);
    }
  })
  const [addEvent] = useAddEvent();

  const updateCode = (value: string) => {
    setCode(value);
    if (codeBoxRef.current) codeBoxRef.current.value = value;
  }

  const sendCount = async () => {
    const count = +value;
    if (count === undefined || count < 0 || isNaN(Number(value)) || sending) return;
    if (history[0] !== code) setHistory([code, ...history]);
    setSending(true);
    await addEvent({
      variables: {
        crossingId: data?.crossing.id,
        count,
        type: correct ? EventType.CorrectSeeds : EventType.CountSeeds
      }
    });
    setTimeout(() => {
      setValue("");
      updateCode("");
      setSending(false);
    }, 1000);
  }

  useEffect(() => {
    if (!sending) codeBoxRef.current?.focus();
  }, [sending]);

  const goToPlot = (plotCode: string) => {
    setHasEnteredCode(true);
    setCode(plotCode);
    setTimeout(() => countBoxRef?.current?.focus(), 100);
  }

  return (
    <div className="CountSeeds">
      <h1 className="header">{ t(correct ? "out" : "title") }</h1>
      <div>
        {t("plot")}
        <input autoFocus ref={codeBoxRef}
               disabled={sending}
               onKeyDown={e => e.key === "Enter" && goToPlot(e.currentTarget.value)}
               onInput={() => setCode("")}
        />
      </div>

      <div>
        {t(correct ? "count-correct" : "count")}
        <input ref={countBoxRef}
               onKeyDown={e => e.key === "Enter" && sendCount()}
               disabled={code.length < 1 || !!error || sending}
               onWheel={e => { e.currentTarget.blur(); const t = e.currentTarget; setTimeout(() => t.focus(), 0); }}
               value={value} onInput={e => setValue(e.currentTarget.value)} />
      </div>

      { data && <div className="result">
        {t('total')}: {data.crossing.seedsCounted} {!!data.crossing.seedsCountedToday && <>({t('today')} {data.crossing.seedsCountedToday})</>}
      </div>
      }
      { error && <div className="result error">{t('not-found')}</div> }


      <MenuButton onClick={sendCount}>{t('next')}</MenuButton>
      { history.length > 0 && (history.length > 1 || history[0] !== code) && <MenuButton onClick={() => {
        if (history[0] === code) {
          updateCode(history[1]);
          setHistory(history.slice(1));
        } else {
          updateCode(history[0]);
        }
        setTimeout(() => countBoxRef?.current?.focus(), 100);
      }}>{t('previous')}</MenuButton> }
      <MenuItem img="bee.png" link="/" name={t('back')} />

      <Suspense><Modal title={t('warning.title')}
             onOk={() => {
               setShowWarning(false);
               countBoxRef?.current?.focus();
             }}
             onCancel={() => {
               setCode("");
               if (codeBoxRef.current) codeBoxRef.current.value = "";
               setShowWarning(false);
               codeBoxRef.current?.focus();
             }}
             open={showWarning}>
        {t('warning.body')}
      </Modal></Suspense>
    </div>
  )
}