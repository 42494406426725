import css from './CountCard.module.css';
import {useTranslate} from "hooks/useTranslate";
import {Add, Remove} from "icons";
import {CSSProperties, useMemo} from "react";
import {AddOrUpdateEvent, Input} from "hooks/useAddOrUpdateEvent";
import {LoadingButton} from "components/LoadingButton/LoadingButton";

interface Props {
  title: string;
  required?: number;
  subTitle?: string;
  allowMore?: boolean;
  symbol?: string;
  input?: Input;
  addOrUpdate?: AddOrUpdateEvent;
  subTitleStyle?: CSSProperties;
  collectedLabel?: string;
}

export const CountCard = ({
  title,
  subTitle,
  required,
  allowMore = false,
  symbol,
  input = {},
  addOrUpdate,
  subTitleStyle,
  collectedLabel = 'collected'
}: Props) => {
  const { t } = useTranslate("card");

  const onIncrement = () => addOrUpdate?.process(input);
  const event = addOrUpdate?.getEvent(input);
  const count = event?.count ?? 0;

  // TODO: better solution
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const total = useMemo(() => required, [input.plotId, input.crossingId, input.plotCode]);

  return <div className={css.card}>
    <h3>{ symbol && <span className={css['symbol']}>{symbol}</span> } {title}<div className={css['sub-title']} style={subTitleStyle}>{subTitle}</div></h3>
    <div>{addOrUpdate ? t(collectedLabel) : t('needed')} <div className={css.counter}>
      { addOrUpdate && <>{(event?.count ?? 0) < 0 ? 0 : event?.count ?? 0}</> }
      { addOrUpdate && required !== undefined && '/' }
      { total } </div></div>
    <div className={css.buttons}>{ count > 0 && <LoadingButton onClick={() => addOrUpdate?.undo(event)}><Remove /></LoadingButton> }
      { (required === undefined || required > 0 || allowMore) && addOrUpdate && <LoadingButton onClick={onIncrement}><Add /></LoadingButton> }
    </div>
  </div>
}