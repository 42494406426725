import "./Modal.scss";
import {PropsWithChildren} from "react";
import {useTranslation} from "react-i18next";

interface Props {
  title: string
  onOk: () => void
  onCancel: () => void
  open: boolean
}

export const Modal = ({ title, open, onOk, onCancel, children }: PropsWithChildren<Props>) => {
  const { t } = useTranslation("t", { keyPrefix: "modal" });

  return <>{ open && <div className="Modal">
    <div className="overlay" />
    <div className="dialog-container">
      <div className="dialog">
        <div className="modal-header">
          {title}
        </div>
        <div>
          {children}
        </div>
        <div className="footer">
          <button onClick={onOk}>Ok</button>
          <button onClick={onCancel}>{t('cancel')}</button>
        </div>
      </div>
    </div>
  </div>
  }</>
}